
import React, { useEffect } from 'react'
import { Button, Container, Col, Row, Table, Card, Form, Modal } from 'react-bootstrap'
import { useState } from 'react';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import { getTransactionDataByComId, insertTransactionDetails, updateTransactionInfo,getCurrentTransactionBycomId, getTransactionEffectiveDate } from '../util/api-call';
import moment from 'moment/moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import './AdminHomeStyle.css';
import './UserViewComplianceStyle.css';





function UserViewCompliance() {
    const location = useLocation();
    const [comId, setComId] = useState('');
    const [comName, setComName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [docFile, setDocFile] = useState('');
    const [isComment, setIsComment] = useState('');
    const [writeComment, setWriteComment] = useState('');
    const [uploadDoc, setUploadDoc] = useState();
    const [comList, setComList] = useState([]);
    const [isApiHaveData, SetIsApiHaveData] = useState('no');
    const [open, setOpen] = React.useState(false);
    const [isCommentWarning, setIsCommentWarning] = useState(false);
    const [isComplianceSubmitted, setIsComplianceSubmitted] = useState(false);
    const [isSubmitWarning, setIsSubmitWarning] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [isSubmittedButtonDisable, setIsSubmittedButtonDisable] = useState(true);
    const [effectiveStartDate, setEffectiveStartDate] = useState('');
    const [effectiveEndDate, setEffectiveEndDate] = useState('');
    // const momeCurrentDate = moment();
    // const momeFutureDate = moment(momeCurrentDate);
    // const momeCurrentMonthStartDate = momeFutureDate.startOf('month').format('DD-MM-YYYY');
    // const momecurrentMonthEndDate = momeFutureDate.endOf('month').format('DD-MM-YYYY');



    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };


    let payloadA = {
        comId: comId,
        uploadedDocFile: uploadDoc,
        comment: writeComment,
        status: 'submitted'
    }

    function handleSelectFile(e) {
        const file = e.target.files[0];
        setUploadDoc(file);
        // console.log(uploadDoc);
    }

    const validate = async () => {
        if (isSubmittedButtonDisable === true) {
            setIsSubmitWarning(true);
        } else {
            setOpen(true)
            callUpdatetransactionInfoAPI();
          
        }
    };

    const callUpdatetransactionInfoAPI = async () => {
        setOpen(true)
        updateTransactionInfo(payloadA, (error, result) => {
            if (error) {
                //console.log(error);
                alert(error);
                setTimeout(() => {
                    setOpen(false)
                }, 100);
            } else {
                // console.log(result);
                CallGetCurrentTransactionBycomId();
                calltransactionAPIbyComId()
                setIsComplianceSubmitted(true)
                setTimeout(() => {
                    setOpen(false)
                }, 1000);

            }
        })
    }

    const calltransactionAPIbyComId = async () => {
        setOpen(true)

        getTransactionDataByComId(comId, (error, result) => {
            if (error) {
                //console.log(error);
            } else {
                // console.log(result.data.data);
                if (result.data.data == "notAvailable") {
                    SetIsApiHaveData('no')
                    // console.log(result.data.data)
                    setTimeout(() => {
                        setOpen(false)
                        //setIsComplianceSubmitted(false)
                    }, 1000);
                } else {
                    SetIsApiHaveData('yes')
                    setComList(result.data.data)
                    // console.log(comList)
                    // for(var i in  result.data.data){
                    //     console.log(comList[i].id);
                    // }
                    setTimeout(() => {
                        setOpen(false)
                        //setIsComplianceSubmitted(false)
                    }, 1000);
                }
            }
        })
    }


    const CallGetCurrentTransactionBycomId = async () => {
        setOpen(true)

        getCurrentTransactionBycomId(comId, (error, result) => {
            if (error) {
                //console.log(error);
            } else {
                if (result.data.data == "notAvailable") {
                    // console.log(result.data.data)
                    setTimeout(() => {
                        setOpen(false)
                    }, 1000);
                } else {
                    // console.log('getCurrentTransactionBycomId')
                    // console.log(result.data.data[0])
                    let momentStartDate = moment();
                    // console.log(moment(result.data.data[0].startDate).format('YYYY-MM-DD'))
                    let d = momentStartDate.format('YYYY-MM-DD')
                     //console.log(result.data.data[0].startDate)
                      //console.log(d);
                    if(result.data.data[0].startDate <= d){
                        //console.log("submitted avai")
                     }else{
                        //console.log("not available submitted")
                      }


                    if(result.data.data[0].status == 'not submitted' && result.data.data[0].startDate <= d){
                        setIsSubmittedButtonDisable(false);
                    }
                    else{
                        setIsSubmittedButtonDisable(true);
                    }
                    setTimeout(() => { 
                        setOpen(false)
                    }, 1000);
                }
            }
        })
    }


    const callTransactionEffectiveDate = async () => {
        setOpen(true)

        getTransactionEffectiveDate(comId, (error, result) => {
            if (error) {
                console.log(error);
            } else {
                console.log(result.data.data[0])
                if (result.data.data == "notAvailable") {
                    setTimeout(() => {
                        setOpen(false)
                    }, 1000);
                } else {
                    setEffectiveStartDate(result.data.data[0].startDate);
                    setEffectiveEndDate(result.data.data[0].endDate);
                    setTimeout(() => {
                        setOpen(false)
                    }, 1000);
                }
            }
        })
    }


    const callDocumentAPI = async (fileName) => {
        //https://uatcompliance.arkafincap.com/users/download/
        setOpen(true)
        fetch('https://compliance.arkafincap.com/users/download/' + fileName)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                setFileData(url);
                setOpen(false)
            })
            .catch(error => console.log(error));
    }


    useEffect(() => {
        setOpen(true)
        setComId(location.state.comId);
        setComName(location.state.comName);
        setUserId(location.state.userId);
        setDocFile(location.state.docFile);
        setStartDate(location.state.startDate);
        setEndDate(location.state.endDate);
        setIsComment(location.state.isComment);
        calltransactionAPIbyComId();
        CallGetCurrentTransactionBycomId();
        callTransactionEffectiveDate();

    }, [comId])

    return (
        <>
            <Container className='shadow-lg border' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Row style={{ padding: 5, marginBottom: '30px', marginRight: -9, marginLeft: -9 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className='h5 UVC-HEADING' style={{ margin: 0, color: 'black' }}>Compliance</p>
                        {/* <a class="link-opacity-100-hover" href="#">Edit</a> */}
                    </Col>
                </Row>


                <Container>
                    {isCommentWarning ? <Alert severity="warning">Please write your comment!</Alert> : null}
                    {isComplianceSubmitted ? <Alert severity="success"  onClose={() => setIsComplianceSubmitted(false)} dismissible >Compliance successfully submitted!</Alert> : null}

                    <Row>
                        <Col sm={8}>                            
                          <Row style={{ alignItems: 'center', padding: 5 }}>
                                <Col sm={4}>
                                    <Card style={{ padding: 5, overflow: 'hidden' }}>
                                        <Col xs={3} md={3}>
                                            <Card.Img variant="top" src="https://d27jswm5an3efw.cloudfront.net/app/uploads/2019/02/doc-document-file-11.jpg" />
                                        </Col>
                                        <Col sm={2}>
                                            <Button variant="primary" type="submit" style={{ color: 'white', fontSize: 'xx-small', backgroundColor: '#06444a', padding: 5 }}>
                                                <a style={{ fontSize: 'xx-small' }} href={fileData} onClick={() => callDocumentAPI(docFile)} download={docFile}>Download</a>
                                            </Button>
                                            {/* <p style={{fontSize:'x-small'}}>To download the assigned Compliance Certificate please click twice on the 'Download' button.</p> */}
                                        </Col>
                                        <Card.Text style={{ fontSize: 'x-small' }}>
                                            To download the assigned Compliance Certificate please click the 'Download' button.
                                        </Card.Text>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ padding: 5, alignItems: 'center' }}>
                                <Col className='UVC-COLHEADING'  sm={6} >
                                    Compliance Name
                                </Col>
                                <Col sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {comName}
                                </Col>
                            </Row>

                            <Row style={{ padding: 5, alignItems: 'center' }}>
                                <Col className='UVC-COLHEADING' sm={6} >
                                    Start Date
                                </Col>
                                <Col sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {moment(effectiveStartDate).format("DD/MM/YYYY")}
                                </Col>
                            </Row>

                            <Row style={{ padding: 5, alignItems: 'center' }}>
                                <Col className='UVC-COLHEADING' sm={6} >
                                    End Date
                                </Col>
                                <Col sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {moment(effectiveEndDate).format("DD/MM/YYYY")}
                                </Col>
                            </Row>

                            <Row style={{ padding: 5, alignItems: 'center' }}>
                                <Col className='UVC-COLHEADING' sm={6} >
                                    Write Comment
                                </Col>
                                <Col sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    <Form.Group controlId="formGridEmail">
                                        <Form.Control type="text"
                                            required
                                            value={writeComment}
                                            onChange={(event) => setWriteComment(event.target.value)}
                                            placeholder="Comment"
                                            disabled={isComment == 'true' ? false : true} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row style={{ padding: 10 }}>
                                {isSubmitWarning ? <Alert style={{ marginBottom: 10 }} onClose={() => setIsSubmitWarning(false)} dismissible severity="warning">The Compliance can be submitted within the prescribed timelines. Post due date submission, the same will be a delayed filing.</Alert> : null}
                                <Col sm={6} style={{ fontSize: 16, fontWeight: 'bold' }}>
                                    <Button  style={{backgroundColor:'#06444a',color:'white',fontSize:'small'}} variant="primary" type="submit" onClick={() => validate()}>
                                        SUBMIT
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='border' sm={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Card style={{ padding: 5, overflow: 'hidden', marginRight: 10 }}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Control type="file" onChange={(e) => handleSelectFile(e)} />
                                </Form.Group>
                                <Card.Text style={{ fontSize: 'x-small' }}>
                                    If there are any changes to be done in the uploaded Compliance Certificate, request you to please upload the file in track mode through the 'Choose File' button.
                                </Card.Text>
                            </Card>
                        </Col>
                                           
                   </Row>
                </Container>

            </Container>


            <Container className='shadow-lg border' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '50px' }}>
                <Row style={{ padding: 5, marginBottom: '30px', marginRight: -9, marginLeft: -9 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p className='h5 UVC-HEADING' style={{ margin: 0, color: 'black' }}>Transaction</p>
                    </Col>
                </Row>

                <Container style={{ 'overflow-x': 'auto' }}>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th className='TABLEHEADER'>S.N</th>
                                <th className='TABLEHEADER' scope="col">User</th>
                                <th className='TABLEHEADER'>Compliance Name</th>
                                <th className='TABLEHEADER'>Org Doc</th>
                                <th className='TABLEHEADER'>User uploaded Doc</th>
                                <th className='TABLEHEADER'>Admin Uploaded Doc</th>
                                <th className='TABLEHEADER'>Department</th>
                                <th className='TABLEHEADER'>Start Date</th>
                                <th className='TABLEHEADER'>End Date</th>
                                <th className='TABLEHEADER'>Comment</th>
                                <th className='TABLEHEADER'>Status</th>
                                <th className='TABLEHEADER'>Submitted on</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                isApiHaveData == 'yes' ?
                                    comList.map((number, i) => {
                                        return (
                                            <tr>
                                                <td className='TABLECOLUMN'>{i+1}</td>
                                                <td className='TABLECOLUMN'>{number.user_email}</td>
                                                <td className='TABLECOLUMN'>{number.comName}</td>
                                                <td className='TABLECOLUMN'><a href={fileData} onClick={() => callDocumentAPI(number.orgDocFile)} download={number.orgDocFile}>{number.orgDocFile}</a></td>
                                                <td className='TABLECOLUMN'><a href={fileData} onClick={() => callDocumentAPI(number.uploadedDocFile)} download={number.uploadedDocFile}>{number.uploadedDocFile}</a></td>
                                                <td className='TABLECOLUMN'><a href={fileData} onClick={() => callDocumentAPI(number.adminUploadedDocFile)} download={number.adminUploadedDocFile}>{number.adminUploadedDocFile}</a></td>
                                                <td className='TABLECOLUMN'>{number.department}</td>
                                                <td className='TABLECOLUMN'>{moment(number.startDate).format("DD/MM/YYYY")}</td>
                                                <td className='TABLECOLUMN'>{moment(number.endDate).format("DD/MM/YYYY")}</td>
                                                <td className='TABLECOLUMN'>{number.comment}</td>
                                                <td className='TABLECOLUMN' style={{color:number.status == 'not submitted' ? 'gray' : number.status == 'submitted' ?  'green' : 'orange' }}>{number.status == 'not submitted' ? 'Not Submitted': number.status == 'submitted' ? 'Submitted' : number.status}</td>
                                                <td className='TABLECOLUMN'>{moment(number.submitted_on).format("lll") == 'Invalid date' ? '...' : moment(number.submitted_on).format("lll")}</td>                                      
                                            </tr>
                                        )
                                    }) : 'empty'
                            }
                        </tbody>
                    </Table>
                </Container>
            </Container>
            {/* <Footer /> */}
        </>
    )
}



export default UserViewCompliance